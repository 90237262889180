













































import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {defineComponent} from '@vue/composition-api';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default defineComponent({
  name: 'UOttawaPostLab3Discussion',
  components: {AiLoadingOverlay, StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        part1a: '',
        part1b: '',
        part1c: '',
        part11: '',
        language: 'en',
      },
      questions: [
        {
          en: 'a) What colour do you observe? What ion causes this colour?',
          fr: 'a) Quelle couleur observez-vous ? Quel ion produit cette couleur?',
          inputName: 'part1a',
        },
        {
          en: 'b) What changes do you observe? From the chemical reactions provided, what chemistry explains the observations you made? What ion(s) cause(s) the colour?',
          fr: 'b) Quels changements observez-vous ? Lesquelles parmi les réactions chimiques dans Tableau 1 peuvent expliquer les observations que vous avez faites? Quel ion produit cette couleur?',
          inputName: 'part1b',
        },
        {
          en: 'c) What changes do you observe? From the chemical reactions provided, what chemistry explains the observations you made? Have you seen this colour before? If so, how can you explain your observations using the given reactions?',
          fr: 'c) Quels changements observez-vous ? Lesquelles parmi les réactions chimiques dans Tableau 1 peuvent expliquer les observations que vous avez faites? Avez-vous vu cette couleur avant? Si oui, comment pouvez-vous expliquer vos observations à l’aide des réactions données?',
          inputName: 'part1c',
        },
        {
          en: '1) In Step 4, are you able to see the same changes or are they different? Do you expect them to be the same or not? Why?',
          fr: '1) À l’étape 4, observez-vous les mêmes changements ou sont-ils différents ? Doivent-ils être pareils ou non? Pourquoi?',
          inputName: 'part11',
        },
      ],
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
});
